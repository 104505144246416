/**
 * 商品相关请求模块
 */
// import qs from 'qs'
import request from '@/utils/request'

export const getManagerList = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/setup'
  })
}

export const getManagerTableData = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_items.json',
    params
  })
}

export const putShopBatch = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_items/batch_action',
    data
  })
}

export const deleteShopItem = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_items/${id}`
  })
}

export const getShopDetails = (id) => {
  return request({
    method: 'get',
    url: `/cms/shop_items/${id}`
  })
}

export const putShopDetails = (id, data) => {
  return request({
    method: 'put',
    url: `/cms/shop_items/${id}`,
    data
  })
}

export const postShopDetails = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_items',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const postImgData = (data) => {
  return request({
    method: 'POST',
    url: '/v2/images',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
export const deleteImageRecord = (id, url) => {
  return request({
      method: 'DELETE',
      url: url ? `/${url}/${id}` : `/v2/images/${id}`
    })

}
export const getExcel = (params) => {
  return request({
    method: 'get',
    url: '/cms/shop_items/export',
    params
  })
}

// 导入
export const insertExcel = (data) => {
  return request({
    method: 'post',
    url: '/cms/shop_items/import',
    data
  })
}

// 复制
export const copyProduct = (id, member_action, data) => {
  return request({
    method: 'post',
    url: `cms/shop_items/${id}/member_action`,
    data
  })
}

export const getShopItemStocksSetup = () => {
  return request({
    method: 'get',
    url: '/cms/shop_item_stocks/setup'
  })
}

export const getShopItemStocksJson = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_item_stocks.json',
    params
  })
}

export const deleteShopItemStocks = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_item_stocks/${id}`
  })
}

export const getShopItemStocks = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_item_stocks/${id}`
  })
}

export const getShopItemsIdPreview = (id) => {
  return request({
    method: 'get',
    url: `/cms/shop_items/${id}/preview`
  })
}

export const getShopCategoriesSetup = () => {
  return request({
    method: 'get',
    url: '/cms/shop_categories/setup'
  })
}

export const getShopCategoriesJson = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_categories.json',
    params
  })
}

export const getShopCategoriesId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_categories/${id}`
  })
}

export const deleteShopCategoriesId = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_categories/${id}`
  })
}

export const getShopSubCategoriesSetup = () => {
  return request({
    method: 'get',
    url: '/cms/shop_sub_categories/setup'
  })
}

export const getShopSubCategoriesJson = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_sub_categories.json',
    params
  })
}

export const getShopSubCategoriesId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_sub_categories/${id}`
  })
}

export const deleteShopSubCategoriesId = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shop_sub_categories/${id}`
  })
}

export const postShopSubCategoriesOnline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_sub_categories/${id}/online`
  })
}

export const postShopSubCategoriesOffline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_sub_categories/${id}/offline`
  })
}

export const getShopItemLabelsSetup = () => {
  return request({
    method: 'get',
    url: '/cms/shop_item_labels/setup'
  })
}

export const getShopItemLabelsJson = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_item_labels.json',
    params
  })
}

export const getShopItemLabelsId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_item_labels/${id}`
  })
}

export const postShopItemsIdOnline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_items/${id}/online`
  })
}

export const postShopItemsIdOffline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_items/${id}/offline`
  })
}

export const postShopItemStocksIdOnline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_item_stocks/${id}/online`
  })
}

export const postShopItemStocksIdOffline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_item_stocks/${id}/offline`
  })
}

export const getShopItemSites = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_items/sites'
  })
}

export const getShopItemItemables = (type, id, site_id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_items/itemables?item_type=${type}&shop_id=${id}&parent_id=${site_id}`
  })
}

export const postShopItemStockTaking = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_items/stock_taking',
    data
  })
}

export const getWholesalePricingTiers = () => {
  return request({
    method: 'GET',
    url: '/cms/wholesale_pricing_tiers/simple_list'
  })
}
